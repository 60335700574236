<template>
  <Autocomplete 
    v-bind="{
      ...$props,
      options,
      value: newValue,
      loading,
      noFilter: true,
      returnObject: true,
      hideNoData: false,
      clearable: true,
      itemText: 'SeekDisplay',
      itemValue: 'Id'
    }"
    @change="updateValue"
    @searchinput="seekInput"
  >
    <!-- <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{data.item.Name}} ({{data.item.Email}}) - [Agency Name]</v-list-item-title>
        <v-list-item-subtitle>{{data.item.MortgageeAddress1}}, {{data.item.MortgageeCity}}, {{data.item.MortgageeState}} {{data.item.MortgageeZip}}</v-list-item-subtitle>
      </v-list-item-content>
    </template> -->
  </Autocomplete>
  
</template>

<script>
import Autocomplete from '@/components/form/Autocomplete.vue'
import { AccountService } from '@/api/AccountService'
import { sanitizeSeek } from '@/scripts/helper'

export default {
  name: "AccountAutocomplete",
  components: {
    Autocomplete
  },
  data() {
    return {
      newValue: null,
      options: [],
      loading: false,
      timeout: null,
    }
  },
  props: {
    value: Object,
    rules: String,
    label: String,
    clearable: Boolean,
    required: Boolean,
    errors: Array,
    disabled: Boolean,
    name: String,
    module: String
  },
  emits: [
    'change'
  ],
  methods: {
      updateValue(v) {

        if (v) {
          this.newValue = v;
        }
        else {
          this.newValue = null;
        }

        this.emitValue();
      },
      emitValue() {
          this.$emit('change', this.newValue)       
      },
      seekInput(q) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.seek(q);
        }, 300)
      },
      seek(q) {
        q = sanitizeSeek(q);

        if (this.newValue && this.newValue.SeekDisplay.indexOf(q) > -1) return;

        if (q && q.length > 2) {
          this.loading = true;
          
          AccountService.seek({q: q})
          .then((data) => {
            this.options = data.map(o => { return o});
          }).catch(() => {
            
          }).finally(() => {
            this.loading = false;
          });
            
        }
      },
      // getAccount(id) {
      //   AccountService.get({id: id})
      //   .then((data) => {
      //     this.options.push(data);
      //   })
      // }
  },
  watch: {
      value: {
          immediate: true,
          deep: true,
          handler(newVal) {
            if (this.newValue != newVal) {
              this.newValue = newVal;
              this.options.push(this.newValue);
            }
          }
      }
  },
}
</script>
